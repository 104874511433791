import React from 'react'
import { i18n } from '../../utils/translates/i18n'
import { avaliateUser } from '../../services/UsersService'

/**
 * props:
 * - data
 * - onEditClick
 * - onStopClick
 * - onStartClick
 */
function UserRow(props) {
  const doAvaliateUser = async val => {
    const success = await avaliateUser(val, props.data.id)
    if (success.status) {
      props.doGetUsers()
    }
  }

  return (
    <tr>
      <td>
        {props.data.activity ? (
          <svg className="icon icon-xs me-2" fill="green" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg className="icon icon-xs me-2" fill="red" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {props.data.nick}
      </td>
      <td>{props.data.name}</td>
      {['developer', 'manager'].includes(props.rule) && [2, 4, 7].includes(props.uid) && (
        <td>
          <select
            className={`form-select ${
              props.data.analyze === 21
                ? 'text-bg-danger'
                : props.data.analyze === 22
                ? 'text-bg-success'
                : 'text-bg-light'
            }`}
            value={props.data.analyze}
            onChange={e => doAvaliateUser(e.target.value)}>
            <option value={1} className="text-bg-light">
              {i18n.t('waiting')}
            </option>
            <option value={22} className="text-bg-success">
              {i18n.t('approved')}
            </option>
            <option value={21} className="text-bg-danger">
              {i18n.t('rejected')}
            </option>
          </select>
        </td>
      )}
      <td>
        <div>{props.data.plan.name}</div>
        <div>
          {parseInt(props.data.plan.id) === 1 ? (
            <button className="btn btn-primary btn-sm" onClick={() => props.onConvertClick(props.data.id)}>
              {i18n.t('convert')}
            </button>
          ) : (
            ''
          )}
        </div>
      </td>
      <td>{i18n.t(props.data.group.name)}</td>
      {/* <td>{props.data.email}</td> */}
      <td>
        <div>{props.data.indicant}</div>
        {['developer', 'manager', 'admlotteries', 'support', 'finance'].includes(props.rule) && (
          <button
            id={'edit' + props.data.id}
            type="button"
            className="btn btn-primary btn-xs"
            title="Edit this User"
            data-bs-toggle="modal"
            data-bs-target="#modalIndicant"
            onClick={props.onEditClick}>
            {i18n.t('edit')} {i18n.t('indicant')}
          </button>
        )}
      </td>
      <td>
        {['developer', 'manager', 'admlotteries', 'support', 'finance'].includes(props.rule) && (
          <button
            id={'edit' + props.data.id}
            type="button"
            className="btn btn-secondary btn-xs ms-2"
            title="Edit this User"
            data-bs-toggle="modal"
            data-bs-target="#modalUser"
            onClick={props.onEditClick}>
            <svg
              className="icon icon-xs"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>{' '}
            {i18n.t('edit')}
          </button>
        )}
        {['developer', 'finance'].includes(props.rule) && (
          <button
            id={'binary' + props.data.id}
            type="button"
            className="btn btn-info btn-xs ms-2"
            title="Edit this User"
            data-bs-toggle="modal"
            data-bs-target="#modalBinaryUser"
            onClick={props.onBinaryClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="icon icon-xs">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
              />
            </svg>{' '}
            {i18n.t('binary')}
          </button>
        )}
      </td>
    </tr>
  )
}

export default UserRow
