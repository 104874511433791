/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Menu from '../../components/Menu/Menu'
import UserRow from './UserRow'
import Pagination from '../../utils/pagination/Pagination'
import { doConvert, getUsers } from '../../services/UsersService'
import UserModal from './UserModal/UserModal'
import UserBinaryModal from './UserModal/UserBinaryModal'
import { i18n } from '../../utils/translates/i18n'
import { toast } from 'react-toastify'
import { decrypt } from '../../utils/crypto'
import LoaderPage from '../../utils/LoaderPage'
import IndicantModal from './UserModal/IndicantModal'

function Users() {
  const rule = decrypt(sessionStorage.getItem('rule'))

  const defaultLocation = useLocation()
  const history = useHistory()
  const [show, setShow] = useState(false)
  const [uid, setUid] = useState('')

  const [users, setUsers] = useState([])
  const [editUser, setEditUser] = useState({})

  // PARA PAGINAÇÃO
  const token = sessionStorage.getItem('token')
  const [search, setSearch] = useState('')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)
  const [exact, setExact] = useState(false)
  const [ordenation, setOrdenation] = useState('asc')

  const doGetUsers = async () => {
    setShow(false)
    const success = await getUsers(token, page, lim, search, exact, ordenation)
    if (success.status) {
      // console.log(success);
      setCount(success.list.count)
      setUsers(success.list.rows)
      setUid(parseInt(success.uid))
      setShow(true)
    } else {
      toast.error(success.error)
      setShow(true)
    }
  }

  useEffect(() => {
    doGetUsers()
  }, [token, page, lim, exact, ordenation])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  function onEditClick(event) {
    const id = event.target.id.replace('edit', '')
    setEditUser(users.find(u => u.id == id))
  }

  function onBinaryClick(event) {
    const id = event.target.id.replace('binary', '')
    setEditUser(users.find(u => u.id == id))
  }

  async function onConvertClick(id) {
    setShow(false)
    const success = await doConvert(token, id)
    if (success.status) {
      toast.success(success.message)
      doGetUsers()
    }
  }

  function onModalSubmit() {
    setEditUser({})
    doGetUsers()
  }

  return (
    <React.Fragment>
      <Menu />
      {!show ? (
        <LoaderPage />
      ) : (
        <main className="content">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
            <div className="d-block">
              <h2 className="h4">{i18n.t('users')}</h2>
            </div>
          </div>
          <Pagination count={count} pageSize={lim}>
            <div className="align-items-center mb-2">
              <div className="alert bg-dark m-0 py-2 d-flex justify-content-around align-items-center">
                <i className="fas fa-search"></i>
              </div>
            </div>
            <div className="input-group me-2 mb-2">
              <input
                type="text"
                className="form-control"
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={`${i18n.t('search')} ${i18n.t('user')}`}
                onBlur={doGetUsers}
              />
              <button className={`btn btn-${exact ? 'success' : 'secondary'}`} onClick={() => setExact(!exact)}>
                {i18n.t(exact ? 'exact' : 'partiallly')}
              </button>
            </div>
            <select className="form-select me-2 mb-2" value={ordenation} onChange={e => setOrdenation(e.target.value)}>
              <option className="bg-light" value="asc">
                {i18n.t('ascent')}
              </option>
              <option className="bg-light" value="desc">
                {i18n.t('descent')}
              </option>
            </select>
            <select className="form-select mb-2" value={lim} onChange={e => setLims(e.target.value)}>
              <option className="bg-light" value="5">
                5 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="10">
                10 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="25">
                25 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="50">
                50 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="100">
                100 {i18n.t('registers')}
              </option>
            </select>
          </Pagination>
          <div className="card card-body border-0 shadow table-wrapper table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="border-gray-200">{i18n.t('nick')}</th>
                  <th className="border-gray-200">{i18n.t('name')}</th>
                  {['developer', 'manager'].includes(rule) && [2, 4, 7].includes(uid) && (
                    <th className="border-gray-200">{i18n.t('analyze')}</th>
                  )}
                  <th className="border-gray-200">{i18n.t('plan')}</th>
                  <th className="border-gray-200">{i18n.t('type')}</th>
                  {/* <th className="border-gray-200">{i18n.t('email')}</th> */}
                  <th className="border-gray-200">{i18n.t('indicant')}</th>
                  {/* <th className='border-gray-200'>{i18n.t('traderbot')}</th> */}
                  <th className="border-gray-200">{i18n.t('action')}</th>
                </tr>
              </thead>
              <tbody>
                {users && users.length ? (
                  users.map(
                    user =>
                      user.nick !== 'START' &&
                      user.nick !== 'mastersystem' && (
                        <UserRow
                          key={user.id}
                          data={user}
                          onEditClick={onEditClick}
                          onBinaryClick={onBinaryClick}
                          onConvertClick={onConvertClick}
                          doGetUsers={doGetUsers}
                          rule={rule}
                          uid={uid}
                          // onStopClick={onStopClick}
                          // onDeleteClick={onDeleteClick}
                        />
                      )
                  )
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </tbody>
            </table>
            <Pagination count={count} />
          </div>
        </main>
      )}
      {/* <Footer /> */}
      {['developer', 'manager', 'admlotteries', 'support', 'finance'].includes(rule) && (
        <UserModal data={editUser} onSubmit={onModalSubmit} rule={rule} />
      )}
      {['developer', 'manager', 'admlotteries', 'support', 'finance'].includes(rule) && (
        <IndicantModal data={editUser} onSubmit={onModalSubmit} rule={rule} />
      )}
      {['developer', 'finance'].includes(rule) && (
        <UserBinaryModal userName={editUser.fullName} binaries={editUser.binary} doSubmit={onModalSubmit} />
      )}
    </React.Fragment>
  )
}

export default Users
